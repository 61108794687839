<template>
  <v-card flat class="px-8 pb-8">
    <v-card-title style="z-index: 2">
      <span class="headline">Create new page</span>
      <v-spacer/>
    </v-card-title>
    <v-card-subtitle v-if="progress > 0">
      <v-progress-linear v-model="progress"></v-progress-linear>
    </v-card-subtitle>
    <v-card-text class="pa-0">
      <validation-observer ref="creation" v-slot="{ passes }">
        <v-form @submit.prevent="passes(submit)">
          <v-row>
            <v-col class="mx-auto" cols="11">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card>
                    <v-card-title>
                      New page metadata
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <validation-provider name="title meta-tag" rules="required|max:256" v-slot="{ errors }">
                            <v-text-field v-model="form.meta.meta_title" type="text" placeholder="Add content for title tag"
                                          :error-messages="errors"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Title meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="description meta-tag" rules="required" v-slot="{ errors }">
                            <v-textarea v-model="form.meta.meta_description" placeholder="Add content for description tag"
                                        :error-messages="errors"
                                        counter>
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Description meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-textarea>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider name="title OG meta-tag" rules="required|max:256" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_title" type="text" placeholder="Add content for OG title tag"
                                          :error-messages="errors"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Title OG meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="URL OG meta-tag"
                                               :rules="{ required: true, max: 256, regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_url" type="text" placeholder="Add content for OG URL tag (https://example.com)"
                                          :error-messages="errors"
                                          pattern="(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>URL OG meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="type OG meta-tag" rules="required" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_type" type="text" placeholder="Add content for OG type tag"
                                          :error-messages="errors"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Type OG meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="image URL OG meta-tag"
                                               :rules="{ required: true, max: 256, regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_image" type="text" placeholder="Add URL for OG image tag (https://example.com/image.jpeg)"
                                          :error-messages="errors"
                                          pattern="(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Image URL OG meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="description OG meta-tag" rules="required" v-slot="{ errors }">
                            <v-textarea v-model="form.og.og_description" placeholder="Add content for OG description tag"
                                        :error-messages="errors"
                                        counter>
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Description OG meta-tag</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-textarea>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 mt-8">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card>
                    <v-card-title>
                      New page slug
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <validation-provider name="page slug" rules="required" v-slot="{ errors }">
                            <v-text-field v-model="form.slug" type="text" placeholder="Add page slug"
                                          counter="256"
                                          :error-messages="errors"
                                          hint="Page slug is an web address that you would like to see in the search bar of browser"
                                          persistent-hint>
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Page Slug</span>
                                  <span>*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="mx-auto" cols="11">
              <v-row class="ma-0 px-0">
                <v-col cols="12" class="ma-0 px-0">
                  <v-card min-height="100">
                    <v-card-title>Content part</v-card-title>
                    <v-card-text>
                      <froala :tag="'textarea'" :config="configuration" v-model="form.main_part"></froala>
                      <template v-if="form.main_part">
                        <label class="font-weight-bold text-h6 my-5 d-block">Main content preview</label>
                        <froalaView v-model="form.main_part"></froalaView>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 px-0">
                  <v-card min-height="100">
                    <v-card-title>Aside part</v-card-title>
                    <v-card-text>
                      <froala :tag="'textarea'" :config="configuration" v-model="form.aside_part"></froala>
                      <template v-if="form.aside_part">
                        <label class="font-weight-bold text-h6 my-5 d-block">Aside preview</label>
                        <froalaView v-model="form.aside_part"></froalaView>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 px-0">
                <v-col cols="12" class="ma-0 px-0">
                  <v-card>
                    <v-card-title>
                      New Page Settings
                    </v-card-title>
                    <v-card-text>
                      <div class="d-flex">
                        <div>
                          <v-checkbox v-model="form.settings.is_in_menu" label="Add page to menu?"></v-checkbox>
                        </div>
                        <template v-if="form.settings.is_in_menu">
                          <div class="ml-4 flex-fill">
                            <validation-provider name="menu item title" rules="required|max:256" v-slot="{ errors }">
                              <v-text-field v-model="form.menu.menu_item" type="text" placeholder="Add menu title"
                                            :error-messages="errors"
                                            counter="256"
                                            label="Menu title"></v-text-field>
                            </validation-provider>
                          </div>
                        </template>
                      </div>
                      <div class="d-flex">
                        <div>
                          <v-checkbox v-model="form.settings.is_slider_on" label="Include slider in page?"></v-checkbox>
                        </div>
                        <template v-if="form.settings.is_slider_on">
                          <div class="ml-4 flex-fill">
                            <validation-provider name="Slider images" rules="max_files:10" v-slot="{ errors }">
                              <v-file-input  v-model="attachments"
                                             @change="chooseFiles"
                                             @click:clear="deleteAttachments"
                                             accept="image/*"
                                             :error-messages="errors"
                                             multiple
                                             hint="* file should not be more than 1Mb"
                                             persistent-hint
                                             show-size
                                             small-chips
                                             prepend-icon="">
                                <template v-slot:prepend-inner>
                                  <v-icon class="mt-2" size="20">mdi-image</v-icon>
                                </template>
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    @click:close="deleteAttachment(index)"
                                    v-if="index < 2"
                                    color="primary accent-4"
                                    dark
                                    label
                                    small
                                    close
                                  >
                                    {{ text }}
                                  </v-chip>

                                  <span
                                    v-else-if="index === 2"
                                    class="overline grey--text text--darken-3 mx-2"
                                  >
                        +{{ attachments.length - 2 }} {{ attachments.length - 2 > 1 ? 'files' : 'file' }}
                      </span>
                                </template>
                              </v-file-input>
                            </validation-provider>
                          </div>
                        </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn type="submit" color="primary">Save page</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import configuration from '@/config/froala.config'
import UploadService from '@/services/upload.service'
export default {
  name: 'Create',
  data () {
    return {
      configuration,
      search: '',
      loading: false,
      confirmation: false,
      form: {
        main_part: null,
        aside_part: null,
        slug: null,
        meta: {
          meta_title: null,
          meta_description: null
        },
        og: {
          og_title: null,
          og_description: null,
          og_url: null,
          og_image: null,
          og_type: null
        },
        settings: {
          is_slider_on: false,
          is_in_menu: false,
          images: []
        },
        menu: {
          menu_item: null,
          slug: null
        }
      },
      attachments: [],
      progress: 0
    }
  },
  methods: {
    submit () {
      UploadService.upload(this.form, `${process.env.VUE_APP_API_URL}/api/pages`, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then(() => {
          this.$router.push({ name: 'dashboard.main' })
          this.progress = 0
        })
    },
    chooseFiles (files) {
      files.forEach(file => {
        this.form.settings.images.push(file)
      })
    },
    deleteAttachment (index) {
      this.attachments.splice(index, 1)
      this.form.settings.images.splice(index, 1)
    },
    deleteAttachments () {
      this.attachments = []
      this.form.settings.images = []
    }
  }
}
</script>

<style scoped>

</style>
